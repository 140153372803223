import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpHeader
} from '@rategravity/own-up-component-library';
import { OwnUpHeaderStyle } from '@rategravity/own-up-component-library/components/typography/header/style';
import React, { PropsWithChildren } from 'react';
import { BoldPrimaryText, PrimaryText } from '../typography';

export const OrderedList = createOwnUpComponent('ol', OwnUpHeaderStyle);

export const Subheading = ({ children }: { children: PropsWithChildren<{}> }) => (
  <OwnUpHeader variant="section" level={3}>
    {children}
  </OwnUpHeader>
);

export const BoldText = ({ children }: { children: PropsWithChildren<{}> }) => (
  <BoldPrimaryText style={{ display: 'inline' }}>{children}</BoldPrimaryText>
);

const ListItem = createOwnUpComponent(
  'li',
  createOwnUpStyle({
    '.list': {
      margin: '19px 0'
    }
  })
);

interface Data {
  header?: string;
  content: string | JSX.Element;
}

export const mapList = (data: Data[], level?: 2 | 3) =>
  data.map(({ header, content }, i) => {
    let head = null;
    if (header) {
      switch (level) {
        case 2:
          head = <OwnUpHeader>{header}</OwnUpHeader>;
          break;
        case 3:
          head = <Subheading>{header}</Subheading>;
          break;
        default:
          head = <em>{header} </em>;
      }
    }
    return (
      <ListItem key={i}>
        {level && head}
        <PrimaryText className="list">
          {!level && head}
          {content}
        </PrimaryText>
      </ListItem>
    );
  });
