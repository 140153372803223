import React from 'react';
import { Layout } from '../components/layout';
import { ContentWrapper, PageWrapper } from '../components/layout/wrappers';
import { UnderlinedLinkInternal } from '../components/links';
import { BoldText, mapList, OrderedList } from '../components/privacy';
import { BoldPrimaryText, PageTitle, PrimaryText } from '../components/typography';
import { CCPASections, sections } from '../data/content/privacy';

const PrivacySection = () => (
  <>
    <PageTitle variant="title">Privacy</PageTitle>
    <PrimaryText>
      <i>Last Updated: April 22, 2021</i>
      <p>
        This Privacy Policy explains how RateGravity, Inc., a Delaware corporation d/b/a Own Up
        (&quot;Own Up,&quot; &quot;Company&quot;, &quot;we,&quot; &quot;our&quot; or &quot;us&quot;)
        collect, use, disclose, and protect information we collect through our online services,
        website, and also any and all communications with us via phone (including your cell-phone),
        email, text or otherwise (each, a &quot;Service&quot; and collectively,
        &quot;Services&quot;). Capitalized terms that are not defined in this Privacy Policy have
        the meaning given to them in our{' '}
        <UnderlinedLinkInternal to="/terms-conditions">Terms of Use</UnderlinedLinkInternal>.
      </p>
      <p>
        When you use the Services, you consent to our collection, use, disclosure, and protection of
        information about you as described in this Privacy Policy.
      </p>
    </PrimaryText>
    <BoldPrimaryText>
      <p>
        BY ACCESSING OR USING THE SERVICES, YOU AGREE TO THIS POLICY. IF YOU DO NOT AGREE TO THIS
        POLICY, PLEASE DO NOT USE THE SERVICE.
      </p>
    </BoldPrimaryText>
    <PrimaryText>
      <p>
        <UnderlinedLinkInternal href="#CCPA">
          Notice for California Residents
        </UnderlinedLinkInternal>
        - find more information about your California Privacy Rights and for our Notice of
        Collection of Personal Information here.
      </p>
    </PrimaryText>
    <OrderedList>{mapList(sections, 2)}</OrderedList>
  </>
);

const CCPASection = () => (
  <>
    <PageTitle variant="title" id="CCPA">
      California Consumer Privacy Act Notice for California Consumers
    </PageTitle>
    <PrimaryText>
      <p>
        This California Consumer Privacy Act Notice for California Consumers (
        <BoldText>&quot;CCPA Notice&quot;</BoldText>) supplements Own Up (
        <BoldText>&quot;Company&quot;</BoldText> or <BoldText>&quot;Own Up&quot;</BoldText>) Privacy
        Policy and Gramm-Leach-Bliley Act (&quot;GLBA&quot;) notice. This notice applies solely to
        California residents. The California Consumer Privacy Act (
        <BoldText>&quot;CCPA&quot;</BoldText>) affords California consumers certain rights over
        their personal information (subject to certain exceptions). This CCPA Notice outlines those
        rights and explains how such rights may be exercised. This CCPA Notice also summarizes our
        data collection and sharing practices, as required by the CCPA. Terms used but not defined
        shall have the meaning ascribed to them in the CCPA. For the purpose of this CCPA Notice, we
        refer to our websites, applications that run on mobile devices and tablets, call centers,
        and other products and services as <BoldText>&quot;Services&quot;</BoldText> or{' '}
        <BoldText>&quot;Sites.&quot;</BoldText>
      </p>
    </PrimaryText>
    <OrderedList>{mapList(CCPASections, 2)}</OrderedList>
  </>
);

export const Privacy = () => (
  <Layout>
    <PageWrapper>
      <ContentWrapper>
        <PrivacySection />
        <CCPASection />
      </ContentWrapper>
    </PageWrapper>
  </Layout>
);

export default Privacy;
