import { createOwnUpComponent, OwnUpBox, OwnUpTable } from '@rategravity/own-up-component-library';
import React from 'react';
import { headers, rows } from '../../data/content/privacy/ccpa-table';
import { colors } from '../../modules/colors';
import { BoldPrimaryText, PrimaryText } from '../typography';

interface Data {
  header: string;
  cells: string[][];
  disclosures: string[];
}

const list = (data: string[]) => (
  <ul style={{ textAlign: 'left' }}>
    {data.map((text) => (
      <li key={text}>
        <p>{text}</p>
      </li>
    ))}
  </ul>
);

const assembleMatrix = (data: Data[]) =>
  data.map(({ header, cells, disclosures }) => [
    <BoldPrimaryText key={header}>
      <p>{header}</p>
    </BoldPrimaryText>,
    ...cells.map((d) => list(d)),
    ...disclosures.map((d, i) => (
      <PrimaryText key={i}>
        <p>{d}</p>
      </PrimaryText>
    ))
  ]);

const TableWrapper = createOwnUpComponent(OwnUpBox, {
  '& td': {
    verticalAlign: 'top'
  },
  '& th': {
    verticalAlign: 'top',
    borderBottom: `1px solid ${colors.GREY}`
  }
});

export const CCPATable = () => (
  <TableWrapper>
    <OwnUpTable
      headers={headers}
      data={assembleMatrix(rows)}
      variant={['striped', 'stickyHeader']}
      style={{ maxWidth: '1280px', maxHeight: '90vh' }}
    />
  </TableWrapper>
);
