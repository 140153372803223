import React from 'react';
import { UnderlinedLink } from '../../../components/links';
import { mapList, OrderedList } from '../../../components/privacy';
import { PrimaryText } from '../../../components/typography';
import { ownUpAddress } from '../../content';

const { street, unit, city, state, zip } = ownUpAddress;

const cookies = [
  {
    header: 'Cookies.',
    content:
      'When you visit the Service, we may send one or more cookies — a small text file containing a string of alphanumeric characters — to your computer that uniquely identifies your browser or stores information or settings on your device. Cookies let us help you log in faster and enhance your navigation through the site. A cookie may also convey information to us about how you use the Service (e.g., the pages you view, the links you click and other actions you take on the Service), and allow us or our business partners to track your usage of the Service over time. A persistent cookie remains on your hard drive after you close your browser. Persistent cookies may be used by your browser on subsequent visits to the site. Persistent cookies can be removed by following your web browser’s directions. A session cookie is temporary and disappears after you close your browser. Our Service may use HTTP cookies, HTML5 cookies and other types of local storage (such as browser-based or plugin-based local storage).'
  },
  {
    header: 'Clear gifs/web beacons.',
    content:
      'When you use the Service, we may employ clear gifs (also known as web beacons) which are used to anonymously track the online usage patterns of our Users. In addition, we may also use clear gifs in HTML-based emails sent to our users to track which emails are opened, which links are clicked by recipients, and whether our emails are forwarded. The information allows for more accurate reporting and improvement of the Service.'
  },
  {
    header: 'Web analytics.',
    content: (
      <>
        We may use third-party Web analytics services on our Service, such as those of Google
        Analytics. These service providers use the technology described above to help us analyze how
        users use the Service. The information collected by the technology (including your IP
        address) will be disclosed to or collected directly by these service providers. To prevent
        Google Analytics from using information for analytics, a user may install the{' '}
        <UnderlinedLink
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener"
        >
          Google Analytics Opt-Out Browser Add-on
        </UnderlinedLink>
        .
      </>
    )
  }
];

const infoCollectedAutomatically = [
  {
    header: 'Log file information.',
    content:
      'Log file information is automatically reported by your browser each time you access the Service. When you use our Service, our servers may automatically record certain log file information. These server logs may include information such as your web request, Internet Protocol ("IP") address, browser type, browser language, operating system, platform type, the state or country from which you accessed the Service, software and hardware attributes (including Device ID), referring / exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed and the order of those pages, the data and time you used the Service and uploaded or posted content, error logs, files you download, and other such information.'
  },
  {
    header: 'Location information.',
    content:
      'When you use the Service, we may automatically collect general location information (e.g., IP address, city/state and or zip code associated with an IP address) from your computer or mobile device. Please note that if you disable such features, you may not be able to access or receive some or all of the services, content, features and/or products made available via the Service.'
  },
  {
    header: 'Cookies and other technologies.',
    content: (
      <>
        We and our third-party service providers may use cookies, clear GIFs, pixel tags, beacons,
        and other technologies that help us better understand user behavior, personalize
        preferences, perform research and analytics, deliver tailored advertising, and improve the
        products and services we provide. You can choose to accept or decline certain cookies. Most
        web browsers automatically accept cookies, but your browser may allow you to modify your
        browser settings to decline certain cookies if you prefer. If you disable cookies, you may
        be prevented from accessing or taking full advantage of the Services.
        <ol>{mapList(cookies)}</ol>
      </>
    )
  }
];

const collectionOfInformation = [
  {
    header: 'Information you provide us directly',
    content: (
      <>
        <p>
          The information we collect from you will vary depending on the product or service
          requested. We may ask for certain information such as your username, first and last name,
          birth date, telephone number, email address, outstanding loan balances, social security
          number, income, employment history and other details related to your financial situation
          when you use our Service and/or register for a Own Up account. We may collect information
          that you voluntarily provide to us when using the Service. All of the information that we
          collect from you directly is identified in the forms, other places where you respond to
          questions, or where you indicate preferences throughout the Service, either online or
          communicated through our customer service representatives.
        </p>
        <p>
          We may also collect and store information that you provide to us about other people. By
          submitting information about someone other than yourself, you represent that you are
          authorized to provide us with that person’s information for the purposes identified in
          this Privacy Policy and/or in connection with the Service.
        </p>
        <p>
          We may also retain any messages you send through the Service, customer service
          correspondence (via telephone, chat, text, email or otherwise). Calls to Own Up may be
          recorded or monitored for quality assurance, customer service, training and/or risk
          management purposes.
        </p>
      </>
    )
  },
  {
    header: 'Information That Is Collected Automatically',
    content: (
      <>
        <p>
          In addition to the information you provide to us directly, we may automatically collect
          information about your use of the Service, as described below.
        </p>
        <ul>{mapList(infoCollectedAutomatically)}</ul>
        <p>
          We may use this information in a number of ways, including: (a) to remember information so
          that you will not have to re-enter it during your visit or the next time you visit the
          site; (b) to provide custom, personalized content and information; (c) to provide our
          Service and monitor its effectiveness, including on or through our affiliates and/or other
          third party service providers; (d) to monitor aggregate metrics such as total number of
          visitors, traffic, and demographic patterns; (e) to diagnose or fix technology problems;
          (f) to help you efficiently access your information after you sign in and; (g) to deliver
          advertising to you.
        </p>
      </>
    )
  },
  {
    header: 'Information from Third-Party Sources',
    content:
      'We may receive information about you from publicly and commercially available sources, as permitted by law, which we may combine with other information we receive from or about you. For example, when you request prequalified rates, we will ask your permission to conduct a soft credit inquiry. Likewise, when you apply for a loan product, either we or a Provider may conduct a hard credit inquiry. When you apply for a mortgage, we will conduct a search of public records to determine the estimated value of your home and your estimated taxes in order to automate the loan application process and make things more convenient and streamlined for you.'
  }
];

const useOfInformation = [
  {
    header: 'To provide you with and manage the Service.',
    content:
      'We use your information to provide, develop, maintain and improve our Service and the features and functionality of the Service. This may include customization and tailoring the Service features according to the information that you provide.'
  },
  {
    header: 'To process and fulfill a request or other transaction submitted to us.',
    content:
      'We use your information to process or fulfill requests or transactions that you have requested.'
  },
  {
    header: 'To communicate with you.',
    content:
      'We use the information we collect or receive to communicate directly with you. We may send you emails containing newsletters, promotions and special offers. If you do not want to receive such email messages, you will be given the option to opt out or change your preferences. We also use your information to send you Service-related emails (e.g., account verification, changes/updates to features of the Service, technical and security notices). You may not opt out of Service-related emails.'
  },
  {
    header: 'Provide personalized services, including content, ads, products or services.',
    content:
      'We may use the information we collect to provide personalized services and advertising to you either through Own Up, Own Up’s affiliates  or a third party marketing partner, subject to the requirements of the Privacy Notice.'
  },
  {
    header: 'Surveys, Offers, Sweepstakes.',
    content:
      'We may use your information to conduct surveys and offer sweepstakes, drawings, and similar promotions.'
  },
  {
    header: 'Marketing and Advertising.',
    content:
      'We may use your information to market and advertise our services and the services of certain third parties we work with.'
  },
  {
    header: 'Unique Identifiers.',
    content:
      'Sometimes, we, our affiliates or our service providers may use the information we collect – for instance, log-in credentials, IP addresses and unique mobile device identifiers – to locate or try to locate the same unique users across multiple browsers or devices (such as smartphones, tablets, or computers), or work with providers that do this, in order to better tailor advertising, content, and features, and provide you with a seamless experience across devices. If you wish to opt out of cross device tracking for purposes of interest-based advertising, you may do so through your Android or Apple device-based settings.'
  },
  {
    header: 'Research and Analytics.',
    content:
      'We may use your information for research and analytical purposes, for example, to identify trends and effectiveness of certain marketing campaigns we run.'
  },
  {
    header: 'Business Operations.',
    content:
      'We may use your information for certain operational purposes including, for example, to perform audits and quality control.'
  },
  {
    header: 'To protect the rights of Own Up and others.',
    content:
      'We may use your information as we believe is necessary or appropriate to protect, enforce, or defend the legal rights, privacy, safety, security, or property of Own Up, its affiliates, employees or agents, or other users and to comply with applicable law.'
  },
  {
    header: 'With your consent.',
    content: 'We may otherwise use your information with your consent or at your direction.'
  },
  {
    header: 'To aggregate/de-identify data.',
    content:
      'We may aggregate and/or de-identify information collected through the Service. We may use de-identified and/or aggregated data for any purpose and without any restrictions, including without limitation for research and marketing purposes, and may also share such data with any third parties, including advertisers, promotional partners, sponsors, event promoters, and/or others.'
  }
];

const sharingWithProviders = [
  {
    content:
      'You agree that when you submit information to a Provider or third-party through the Service, such submission of your information may also be governed by Provider or third-party privacy and security policies which differ from our Privacy Policy. For details of these policies, please consult the policies of the Providers or third-parties directly.'
  },
  {
    content:
      'You understand that, subject to any applicable laws or regulations, Providers may keep your information that relates to any submission of information that you may make through your use of the Service and any other information provided by Own Up or Own Up affiliates in the processing of any submission of information, whether or not you are eligible for the Provider’s products or services. You agree to notify any particular Provider directly if you no longer want them to keep your information.'
  },
  {
    content:
      'As a prospective borrower or applicant of a Provider’s products or services, you agree that when you nominate a co-signer/co-applicant, Own Up may share Provider responses relating to your submission with your nominated co-signer/co-applicant, and, as a co-signer/co-applicant, we may share Provider responses relating to your submission with the prospective borrower or applicant.'
  }
];

const sharingInformation = [
  {
    header: 'Providers.',
    content: (
      <>
        We may share your information with Providers for the purpose of providing the Service to you
        such that they may provide you with information about your request for prequalified rates,
        offers, and information related to other products and services they offer.
        <ul>{mapList(sharingWithProviders)}</ul>
      </>
    )
  },
  {
    header: 'Affiliates.',
    content:
      'We may share information with our parent company and any of our parent company’s subsidiaries.'
  },
  {
    header: 'Service providers.',
    content:
      'We may share your personal information with third parties who work on behalf of, or with, us such as vendors, processors, agents and other representatives. Service providers assist us with a variety of functions. This includes, for example, assisting with customer service-related functions, providing advertising (including interest-based advertising) and analytics services (e.g., Google Analytics and Facebook Custom Audiences), helping us verify your identity, obtain your credit report, providing website hosting services, assisting with auditing functions, and helping us with marketing and related research activities.'
  },
  {
    header: 'Marketing partners.',
    content:
      'We may share your personal information with third-party marketing partners including partners who we conduct joint-marketing activities with or who we offer a co-branded service with. Marketing partners also include those entities who maintain a link to our Services on their site, or a Own Up widget on their site, when you interact with that widget or click from their site to our Services.'
  },
  {
    header: 'Other parties when required by law or as necessary to protect our users and services.',
    content:
      'Own Up may share your information where required to do so by law or subpoena or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) enforce our Terms of Service or to protect the security or integrity of our Service; and/or (c) exercise or protect the rights, property, or personal safety of Own Up, our Users or others.'
  },
  {
    header: 'Business transfers.',
    content:
      'We may buy or sell/divest/transfer Own Up (including any shares in Own Up), or any combination of its products, services, assets and/or businesses. Your information, which may include NPI, usernames and email addresses, and other user information related to the Service may be among the items sold or otherwise transferred in these types of transactions. We may also sell, assign or otherwise transfer such information in the course of corporate divestitures, mergers, acquisitions, bankruptcies, dissolutions, reorganizations, liquidations, similar transactions or proceedings involving all or a portion of Own Up.'
  },
  {
    header: 'Aggregated or de-identified information.',
    content:
      'We may also aggregate or de-identify data and may share that data without restriction.'
  },
  {
    header: 'Otherwise with your consent or at your direction.',
    content:
      'In addition to the sharing described in this Privacy Policy, we may share information about you with third-parties whenever you consent to or direct such sharing.'
  }
];

const choices = [
  {
    header: 'Your Account Information and Settings',
    content: (
      <>
        If you choose to set up a profile, you may update your account information at any time by
        logging into your account and changing your profile settings. You can also contact us at{' '}
        <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink> to change
        your preferences.
      </>
    )
  },
  {
    header: 'Email Promotions',
    content: (
      <>
        You can stop receiving promotional email communications from us by clicking on the
        &quot;unsubscribe link&quot; provided in such communications. We make every effort to
        promptly process all unsubscribe requests. You may not opt out of Service-related
        communications (e.g., account verification, transaction-related updates, changes/updates to
        features of the Service, and technical and security notices). If you have any questions
        about reviewing or modifying your account information, you can contact us directly at{' '}
        <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink>.
      </>
    )
  },
  {
    header: 'Opt-Out and Annual Notice',
    content:
      'If you have a financial product or service with us, we will use and share any information that we collect from or about you in accordance with our Privacy Notice, which offers you certain choices with respect to the use and sharing of your personal information, including the right to opt-out to the sharing of certain information.'
  }
];

export const sections = [
  {
    header: 'Collection of Your Information',
    content: (
      <PrimaryText>
        <p>
          We may collect and store various information about you including, for example, information
          that you provide to us, information that we automatically collect through your use of the
          Service, and information from publicly available sources or third parties, as further
          detailed below. If you utilize our Services to obtain a financial product or service
          through us, we will use and share any information that we collect from or about you in
          accordance with this policy, which offers you certain choices with respect to the use and
          sharing of your personal information. Information subject to this policy is referred to as
          Non-Public Personal Information (&quot;NPI&quot;).
        </p>
        <OrderedList variant="section" type="A">
          {mapList(collectionOfInformation, 3)}
        </OrderedList>
      </PrimaryText>
    )
  },
  {
    header: 'Do Not Track Signals and Similar Mechanisms',
    content: (
      <PrimaryText>
        <p>
          Some Internet browsers may be configured to send &quot;Do Not Track&quot; signals to the
          online services that you visit. We currently do not respond to &quot;Do Not Track&quot; or
          similar signals. To find out more about &quot;Do Not Track,&quot; please visit{' '}
          <UnderlinedLink
            href="http://www.allaboutdnt.com"
            target="_blank"
            rel="noopener"
            alt="all about DNT website"
          >
            http://www.allaboutdnt.com
          </UnderlinedLink>
          .
        </p>
      </PrimaryText>
    )
  },
  {
    header: 'Use of Your Information',
    content: (
      <PrimaryText>
        <p>
          We may use your information and/or combine any or all elements of your information for a
          number of purposes, including:
        </p>
        <ul>{mapList(useOfInformation)}</ul>
      </PrimaryText>
    )
  },
  {
    header: 'Sharing of your information',
    content: (
      <PrimaryText>
        <p>
          We may share the information we collect or receive from you in a variety of circumstances,
          including as listed below. Where appropriate, we will limit sharing of your information in
          accordance with the choices you have provided us in response to our Privacy Notice.
        </p>
        <ul>{mapList(sharingInformation)}</ul>
      </PrimaryText>
    )
  },
  {
    header: 'How We Store and Protect Your Information',
    content: (
      <PrimaryText>
        <p>
          <em>Keeping Your Information Safe.</em> Own Up takes technical and organizational measures
          to protect your data against accidental or unlawful destruction or accidental loss,
          alteration, unauthorized disclosure or access. These measures vary depending on the
          sensitivity of the information we have collected from you. However, no method of
          transmission over the Internet or via mobile device, or method of electronic storage, is
          absolutely secure. Therefore, while we strive to use commercially acceptable means to
          protect your information, we cannot guarantee its absolute security. Own Up is not
          responsible for the functionality or security measures of any third-party or Provider.
        </p>
        <p>
          To protect your privacy and security, we take steps (such as requesting a unique password)
          to verify your identity before granting you access to your account. You are responsible
          for maintaining the secrecy of your unique password and account information, and for
          controlling access to your email communications from Own Up, at all times. Do not share
          your password with anyone and do limit your access to your computer or other devices by
          signing off after you have finished accessing your account.
        </p>
        <p>
          For information about how to protect yourself against identity theft, please refer to the{' '}
          <UnderlinedLink
            href="https://www.ftc.gov/news-events/media-resources/identity-theft-and-data-security"
            target="_blank"
            rel="noopener"
          >
            Federal Trade Commission’s website
          </UnderlinedLink>
          .
        </p>
      </PrimaryText>
    )
  },
  {
    header: 'Your Choices About Your Information',
    content: (
      <OrderedList variant="section" type="A">
        {mapList(choices, 3)}
      </OrderedList>
    )
  },
  {
    header: 'How Long We Keep Your User Content',
    content: (
      <PrimaryText>
        <p>
          Following termination or deactivation of your User account, Own Up may retain your profile
          information for an additional period of time for backup, archival, or audit purposes. In
          certain circumstances, we are required by Federal and State regulations to store various
          information. In order to comply with those requirements, we may be unable to delete your
          information from our database until the legally required retention period expires.
        </p>
      </PrimaryText>
    )
  },
  {
    header: 'Children’s Privacy',
    content: (
      <PrimaryText>
        <p>
          Own Up does not knowingly collect or solicit any information from anyone under the age of
          16 or allow such persons to register as Users. In the event that we learn that we have
          collected personal information from a child under the age of 16, we will promptly delete
          that information. If you believe that we might have collected any information from a child
          under 16, please contact us at{' '}
          <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink>.
        </p>
      </PrimaryText>
    )
  },
  {
    header: 'Links to Other Web Sites and Services',
    content: (
      <PrimaryText>
        <p>
          We are not responsible for the practices employed by websites or services linked to or
          from the Service or to which you direct Own Up to retrieve information, including the
          information or content contained therein. Please remember that when you use a link to go
          from the Service to another website, our Privacy Policy does not apply to third-party
          websites or services. Your browsing and interaction on any third-party website or service,
          including those that have a link or advertisement on our website, are subject to that
          third-party’s own rules and policies. In addition, you agree that we are not responsible
          for, and we do not have control over, any third-parties that you authorize to access your
          User Content. If you are using a third-party website or service and you allow such a
          third-party access to your User Content, you do so at your own risk. Own Up is also not
          responsible for sites directing you to Own Up. Our Terms of Service and Privacy Policy do
          not apply to those third-party sites either.
        </p>
      </PrimaryText>
    )
  },
  {
    header: 'Your California Privacy Rights',
    content: (
      <PrimaryText>
        <p>
          California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law,
          permits Users who are California residents to request and obtain from us once a year, free
          of charge, information about the personal information (if any) we disclosed to
          third-parties for direct marketing purposes in the preceding calendar year. If applicable,
          this information would include a list of the categories of personal information that were
          shared and the names and addresses of all third-parties with which we shared information
          in the immediately preceding calendar year. If you are a California resident and would
          like to make such a request, please submit your request in writing to:
        </p>
        <p>Attention: Legal Department</p>
        <p>Own Up</p>
        <p>{`${street}, ${unit}`}</p>
        <p>{`${city}, ${state} ${zip}`}</p>
        <p>
          The California Consumer Privacy Act (&quot;CCPA&quot;) also affords California residents
          certain rights over their personal information (subject to certain exceptions). Our CCPA
          Privacy Notice, which supplements this Privacy Policy, outlines those rights and explains
          how they may be exercised.
        </p>
      </PrimaryText>
    )
  },
  {
    header: 'Complaints and How to Contact Us',
    content: (
      <PrimaryText>
        <p>
          To make a complaint about a privacy-related matter, or to request access to or correction
          of your personal information, or if you have any questions about this Privacy Policy or
          the Service, please contact us at{' '}
          <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink> or by
          phone at <UnderlinedLink href="tel:8449472848">(844) 947-2848</UnderlinedLink>. We will
          review all complaints and determine whether the matter needs further investigation. In the
          event an investigation is conducted concerning your personal information, we will notify
          you of the outcome of the investigation.
        </p>
      </PrimaryText>
    )
  },
  {
    header: 'Changes to Our Privacy Policy',
    content: (
      <PrimaryText>
        <p>
          Own Up may modify or update this Privacy Policy from time to time to reflect changes in
          our business and practices, and so you should review this page periodically
          (www.ownup.com/privacy). If we make changes to this Privacy Policy, we will revise the
          &quot;Last Updated&quot; date at the top of this policy. If we make a material change to
          this Privacy Policy, we will notify you as required by law. Your continued use of the
          Service following any changes signifies your acceptance of our Privacy Policy as modified.
        </p>
      </PrimaryText>
    )
  }
];
